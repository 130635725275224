(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/lb-utils/scroll-smoothly/assets/javascripts/scroll-smoothly.js') >= 0) return;  svs.modules.push('/components/lb-utils/scroll-smoothly/assets/javascripts/scroll-smoothly.js');
"use strict";



function scrollHorizontally(element, newScrollLeft) {
  let {
    hasScrollSnap = false
  } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  if (!element || typeof newScrollLeft !== 'number') {
    return;
  }
  const _left = Math.min(newScrollLeft, element.scrollWidth - element.getClientRects()[0].width);
  if (element.scrollLeft === _left) {
    return;
  }
  _addTemporaryCssStyle(element, {
    phase: 'setup',
    style: 'scroll-behavior: smooth;'
  });
  const hasNativeSupport = window.getComputedStyle(element).scrollBehavior === 'smooth';
  _addTemporaryCssStyle(element, {
    phase: 'cleanup'
  });
  if (hasNativeSupport) {
    element.scrollTo({
      left: _left,
      behavior: 'smooth'
    });
    return;
  }
  if (element.dataset.isScrolling) {
    element.dataset.isScrolling = 'canceled';
    element.dataset.newScrollLeft = String(_left);
    return;
  }
  element.dataset.isScrolling = 'true';
  if (hasScrollSnap) {
    for (let i = 0; i < element.children.length; i++) {
      _addTemporaryCssStyle(element.children[i], {
        phase: 'setup',
        style: 'scroll-snap-align: none;',
        backupStyle: 'scrollSnapAlign'
      });
    }
  }
  const done = () => {
    if (hasScrollSnap) {
      for (let i = 0; i < element.children.length; i++) {
        _addTemporaryCssStyle(element.children[i], {
          phase: 'cleanup'
        });
      }
    }
    if (element.dataset.isScrolling === 'canceled') {
      element.scrollTo(parseInt(element.dataset.newScrollLeft, 10), 0);
      delete element.dataset.newScrollLeft;
    } else {
      element.scrollTo(_left, 0);
    }
    delete element.dataset.isScrolling;
  };
  _scrollX(element, _left - element.scrollLeft, done);
}

function _scrollX(element, scrollOffsetX, done) {
  if (scrollOffsetX === 0) {
    if (done) {
      done();
    }
    return;
  }
  const durationMS = 1000;
  const easeCubicOut = t => t * t * t + 1;
  const dummyPoints = new Array(60).fill(0).map((_, index) => easeCubicOut(59 - index));
  const dummyPointsSum = dummyPoints.reduce((acc, val) => acc + val, 0);
  const easingPoints = new Array(60).fill(0).map((el, i) => Math.round(scrollOffsetX * dummyPoints[i] / dummyPointsSum)).filter(value => value !== 0);
  let i = 0;
  const step = () => {
    element.scrollBy(easingPoints[i++], 0);
    if (i < easingPoints.length) {
      setTimeout(() => window.requestAnimationFrame(step), durationMS / easingPoints.length);
    } else if (done) {
      done();
    }
  };
  window.requestAnimationFrame(step);
}

function _addTemporaryCssStyle(element, _ref) {
  let {
    phase,
    style = null,
    backupStyle = null
  } = _ref;
  if (phase === 'setup') {
    if (backupStyle) {
      element.dataset.backupStyle = window.getComputedStyle(element)[backupStyle];
    }
    const tempStyle = element.getAttribute('style');
    if (tempStyle) {
      element.dataset.tempStyle = tempStyle;
      element.setAttribute('style', tempStyle.trim().endsWith(';') ? "".concat(tempStyle, " ").concat(style) : "".concat(tempStyle, "; ").concat(style));
    } else {
      element.setAttribute('style', style);
    }
  }
  if (phase === 'cleanup') {
    delete element.dataset.backupStyle;
    const tempStyle = element.dataset.tempStyle;
    if (tempStyle) {
      delete element.dataset.tempStyle;
      element.setAttribute('style', tempStyle);
    } else {
      element.removeAttribute('style');
    }
  }
}

setGlobal('svs.components.lbUtils.scrollSmoothly.scrollHorizontally', scrollHorizontally);

 })(window);